<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="plus" @click="$router.push(`${resource.route}/new`)">Add {{ resource.name }}</sc-button>

            <div style="display: flex;">
                <sc-search :table="table" field="q" style="margin-right: 1em;"></sc-search>
                <sc-button icon="download" @click="exportMail">Export email addresses</sc-button>
                <div class="gutter"></div>
                <sc-button icon="download" @click="exportUsers">Export</sc-button>
            </div>
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        <div>
                            Do you wish to delete the {{ resource.name }}
                        </div>
                        <div style="margin-top:1.5em;text-align: center;font-weight:600;">
                            {{ row.attributes.first_name }} {{ row.attributes.last_name }}
                        </div>
                        <div style="text-align: center">
                            {{ row.attributes.email }}
                        </div>
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>
        </sc-table>
    </div>
</template>

<script>
import moment from 'moment';
import ScSearch from "@/components/sc-search";

export default {
  name: "all",
  components: {ScSearch},

  data() {
    return {
      table: null
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      sortBy: 'name',
      columns: {
        first_name: {
          target: 'attributes.first_name',
          sort: false,
        },
        last_name: {
          target: 'attributes.last_name',
          sort: false,
        },
        email: {
          target: 'attributes.email',
          fill: true,
          sort: false,
        },
        edit: {width: 70, sort: false,},
        delete: {width: 80, sort: false}
      },
      hover: false,
    });
    this.table.fetch();
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`User deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${this.resource.route}/${row.id}/edit`)
    },

    exportMail() {
      this.$talker.api('/users/export-ds-emails', {
        responseType: 'blob'
      })
        .then((res) => {
          const name = `export-emails-ds-users-${this.getDateTimeStamp()}.xlsx`;
          this.downloadFile(this.createExcelFile(res.data), name);
        })
    },

    exportUsers() {
      this.$talker.api('/users/export', {
        responseType: 'blob'
      })
        .then((res) => {
          const name = `export-users-${this.getDateTimeStamp()}.xlsx`;
          this.downloadFile(this.createExcelFile(res.data), name);
        })
    },

    getDateTimeStamp() {
      // format YYYY-MM-DD_HHhmm
      return moment().format('YYYY-MM-DD_HH%mm').replace('%', 'h')
    },

    createExcelFile(data) {
      return new Blob([data], {type: 'application/vnd.ms-excel'});
    },

    downloadFile(blob, name) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.gutter {
    margin: .5em;
}

</style>